h1{
    color: #0000;
    text-align: center;
    vertical-align: middle;
    font-family: 'Papyrus', Verdana, Geneva, sans-serif;
    font-weight: bold;
    font-size: 48px;
    background-image: linear-gradient(to bottom right, #4CAF50, #008CBA);
    height: 70px; 
    width: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    background-clip: text;
    -webkit-background-clip: text;
    /* text-shadow: 0 0 3px #008CBA, 0 0 5px #4CAF50; */
  }
  .game_vs_AI-container .img{
    height: 50px;
    width: 50px;
  }
  
  .game_vs_AI-container .button {
      align-self: center;
      font-family: 'Verdana';
      border: none;
      color: white;
      padding: 0px 0px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      /* font-size: 16px;
      margin: 4px 4px;
      transition-duration: 0.4s; */
      cursor: pointer;
    }
    
  .game_vs_AI-container   .button1 {
      background-color: white;
      color: black;
      border: 3px solid #4CAF50;
    }
    
    .game_vs_AI-container   .button1:hover {
      background-color: #4CAF50;
      color: white;
    }
    
    .game_vs_AI-container   .button2 {
      background-color: white;
      color: black;
      border: 3px solid #008CBA;
    }
    
    .game_vs_AI-container .button2:hover {
      background-color: #008CBA;
      color: white;
    }
  
    /* table, td{
        border: 2px solid;
        align-content: center;
        border-style: solid;
    } */
  
    .game_vs_AI-container .tab1{
        border: #008CBA 2px solid;
        border-collapse: collapse;
        padding: 5px;
    }
    .game_vs_AI-container .tab2{
        border: #4CAF50 2px solid;
        border-collapse: collapse;
        padding: 5px;
  
    }
  
    .button img {
      display: block;
      padding: 0;
      margin: 0;
      width: 50px;
      height: 50px;
    }
    /* @font-face {
    font-family: 'Luminari';
    font-style: normal;
    font-weight: normal;
    src: url('luminari-regular.woff') format('woff');
    } */
  
  
  
    .game_vs_AI-container .pc {
        display: flex;
        justify-content: center; /* Center horizontally */
        align-items: center; /* Center vertically */
    }
  
    .game_vs_AI-container h3{
        color: #000000;
        text-align: center;
        font-family: 'Verdana';
    }

    .last-move {
      /* border-width: 10px; */
      display: flex;
      background-color: rgba(255, 255, 255, 1);
      color: black;
      border: 3px solid red;
      /* border: solid red; Customize as needed */
  
  }