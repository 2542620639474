.verify-container body{
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.verify-container h1 {
    color: #000000;
    text-align: center;
    vertical-align: middle;
    font-family: 'Papyrus', Verdana, Geneva, sans-serif;
    font-weight: bold;
    font-size: 48px;
    background-image: linear-gradient(to bottom right, #4CAF50, #008CBA);
    height: 70px; 
    width: 500px;
    display: flex; /* Use flexbox to center the text vertically */
    justify-content: center; /* Center horizontally */
    align-items: center;
    margin: 0;
    background-clip: text;
    -webkit-background-clip: text;
    /* text-shadow: 0 0 3px #008CBA, 0 0 5px #4CAF50; */
}

.verification-container {
    max-width: 400px;
    height: 350px;
    margin: 50px auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: rgba(0, 0, 0, 0.15);
    backdrop-filter: blur(10px);
  }

  .toggle-buttons {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
  }
  
  .toggle-buttons button {
    width: 60%;
    margin: -20px;
    font-family:Verdana, sans-serif;
    font-size: 18px;
    padding: 10px 15px;
    cursor: pointer;
    border: none;
    border-radius: 4px;
    background-color: #008CBA;
    color: #fff;
    font-size: 16px;
    transition: background-color 0.3s ease;
  }
  
  .toggle-buttons button.sgn {
    border-radius: 4px 4px 30px 4px;
    background-color: #00ACBA;
    z-index: 1;
  }

  .toggle-buttons button.lgn {
    border-radius: 4px 4px 4px 30px ;
    background-color: #00ACBA;
    z-index: 1;
  }
  /* Add more styles as needed */
  
.submit{
    padding: 10px 20px;
    width: 150px;
    font-family: Verdana, Geneva, Tahoma, sans-serif, sans-serif;
    font-size: 16px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition-duration: 0.4s;
}

.bd-lgn{
    margin-top: 135px;
    display:flex;
    justify-content:center; /* Center horizontally */
}

.bd-sgn{
    display:flex;
    justify-content:center; /* Center horizontally */
}
.submit:hover {
    background-color: #4CCF50;
    color: black;
  }

.verify-container h1{
    font-family: 'Papyrus', sans-serif;
    font-weight: bold;
    font-size: 48px;
    height: 100px;
    width: 500px;
    color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
}

.verify-container h2{
    font-family: Helvetica, 'Trebuchet MS', sans-serif;
}

.verify-container .button {
    padding: 10px 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    cursor: pointer;
}

.verify-container .form-container {
    font-family: Helvetica, sans-serif;
    width: 500px;
    margin: auto;
}

.form-container label, .form-container input {
    font-family: 'Trebuchet MS', sans-serif;
    font-size: 18px;
    display: block;
    margin-bottom: 10px;
}

.form-container input {
    font-size: 16px;
    width: 95%;
    padding: 5px;
}

.verify-container .form-container button {
    padding: 10px 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    cursor: pointer;
}

 #light{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
