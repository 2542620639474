.profile-page-container h1 {
    color: #000000;
    text-align: center;
    vertical-align: middle;
    font-family: 'Papyrus', Verdana, Geneva, sans-serif;
    font-weight: bold;
    font-size: 48px;
    background-image: linear-gradient(to bottom right, #4CAF50, #008CBA);
    height: 70px; 
    width: 500px;
    display: flex; /* Use flexbox to center the text vertically */
    justify-content: center; /* Center horizontally */
    align-items: center;
    margin: 0;
    background-clip: text;
    -webkit-background-clip: text;
    /* text-shadow: 0 0 3px #008CBA, 0 0 5px #4CAF50; */
}

.profile-page-container  .blur{
    background-color: rgba(0, 0, 0, 0.13);
    backdrop-filter: blur(10px);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  }
.profile-page-container body{
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.profile-block {
    position: absolute;
    top: 15%;
    left: 25%;
    width: 50%;
    height: 70%;
    margin: auto;
    padding: 20px;
    background-color: #f0f0f0;
}

.profile-block h2 {
    text-align: left;
}

.profile-page-container table {
    width: 100%;
    margin-bottom: 20px;
}

.profile-page-container table th, table td {
    padding: 8px;
    text-align: center;
}

.friend-list {
    width: 100%;
    margin-bottom: 20px;
}

.friend-list th, .friend-list td {
    padding: 8px;
    text-align: center;
}



.profile-page-container h1{
    font-family: 'Papyrus', sans-serif;
    font-weight: bold;
    font-size: 48px;
    height: 100px;
    width: 500px;
    color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
}

.profile-page-container h2{
    font-family: 'Comic Sans MS', sans-serif;
    font-weight: bold;
    font-size: 32px;
    padding-left: 50px;
}

.profile-page-container h3{
    font-family: 'Trebuchet MS', sans-serif;
    font-weight: bold;
    size: 24px;
}

.profile-page-container input{
    width: 55%;
    height: 35px;
    font-Family: Tahoma, sans-serif;
    font-size: 18px;
    padding-left: 10px;
}
.profile-page-container .button {
    width: 35%;
    height: 35px;
    font-Family: Tahoma, sans-serif;
    font-size: 16px;
    background-color: #4CAF50;
    color: white;
    border: none;
    cursor: pointer;
}

.tmp{
    margin-bottom: 50px;
    margin-left: 50px;
}

.profile-page-container  #light {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }