.challenge-page-container body{
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.challenge-page-container h1 {
    color: #0000;
    text-align: center;
    vertical-align: middle;
    font-family: 'Papyrus', Verdana, Geneva, sans-serif;
    font-weight: bold;
    font-size: 48px;
    background-image: linear-gradient(to bottom right, #4CAF50, #008CBA);
    height: 70px; 
    width: 500px;
    display: flex; /* Use flexbox to center the text vertically */
    justify-content: center; /* Center horizontally */
    align-items: center;
    margin: 0;
    background-clip: text;
    -webkit-background-clip: text;
    /* text-shadow: 0 0 3px #008CBA, 0 0 5px #4CAF50; */
}

.challenge-page-container .pc {
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
}

.challenge-page-container .button {
    align-self: center;
    font-family: 'Verdana';
    border: none;
    border-radius: 20% 5%;
    color: white;
    padding: 28px 48px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 28px;
    margin: 8px 4px;
    transition-duration: 0.4s;
    cursor: pointer;
}

.challenge-page-container  .button1 {
background-color: rgba(255, 255, 255, 1);
color: black;
border: 4px solid #4CAF50;
}

.challenge-page-container  .button1:hover {
background-color: #4CAF50;
color: white;
}

.challenge-page-container .section {
    margin-bottom: 20px;
}

.challenge-page-container .button{
    padding: 12px 12px;
}

.challenge-page-container .section h2 {
    font-family: 'Trebuchet MS', sans-serif;
}

.challenge-page-container .challenge-button {
    display: inline-block;
    padding: 8px 16px;
    background-color: #4CAF50;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 14px;
    transition-duration: 0.4s;
    text-align: center;
    text-decoration: none;
    outline: none;
    margin-right: 10px;
}

.challenge-page-container .challenge-button:hover {
    background-color: #45a049;
}

.challenge-page-container .td_outer{
    vertical-align: top;
    padding-left: 50px;
    padding-right: 50px;
}

.challenge-page-container .td_inner{
    
    justify-content: flex-end;
}


.last-move {
    /* border-width: 10px; */
    display: flex;
    background-color: rgba(255, 255, 255, 1);
    color: black;
    border: 3px solid red;
    /* border: solid red; Customize as needed */

}
.chat-panel {
    align-self: left;
    width: 30%;
    /* z-index: ; */
}

.chat-table {
    width: 100%;
    border-collapse: collapse;
}

.chat-table td {
    width: 400px;
    padding: 5px;
}

.player-message {
    /* font-weight: bold; */
    width: 100%;
    text-align: right;
    align-self: right;
}

.opponent-message {
    text-align: left;
}

.player-initial {
    font-family: "Comic Sans MS", cursive;
    font-size: 18px;
}

.message-text {
    font-family: Trebuchet MS,Geneva, Tahoma, sans-serif;
    font-size: 16px;
}

.sendbutton {
    padding: 10px 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    cursor: pointer;
}

.game-container {
display: flex;
justify-content: left;
}

.game-board {
    width: 60%;
    padding-right: 0px;
}


.user-details,
.opponent-details {
    text-align: center;
}
.chat-messages {
    height: 400px;
    overflow-y: scroll;
    border: 1px solid #ccc;
    padding: 10px;
    margin-bottom: 10px;
}

.chat-input {
    width: auto;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.chat-input input[type="text"] {
    width: 70%;
    height: 24px;
    font-family: Trebuchet MS, Geneva, Tahoma, sans-serif;
    font-size: 16px;
    padding-left: 10px;
}

.chat-input button {
    width: 30%;
    padding: 5px 10px;
    background-color: #4CAF50;
    color: white;
    border: none;
    cursor: pointer;
    margin-left: 10px;
}
th{
    width: 300px;
}