.home-page-container  h1 {
  color: #000000;
  text-align: center;
  vertical-align: middle;
  font-family: 'Papyrus', Verdana, Geneva, sans-serif;
  font-weight: bold;
  font-size: 48px;
  background-image: linear-gradient(to right, #4CAF50, #008CBA);
  height: 70px; 
  width: 500px;
  display: flex; /* Use flexbox to center the text vertically */
  justify-content: center; /* Center horizontally */
  align-items: center;
  margin: 0;
  background-clip: text;
  -webkit-background-clip: text;
  /* text-shadow: 0 0 3px #008CBA, 0 0 5px #4CAF50; */
}

.home-page-container    .button {
    align-self: center;
    font-family: 'Verdana';
    border: none;
    border-radius: 20% 5%;
    color: white;
    padding: 28px 48px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 28px;
    margin: 8px 4px;
    transition-duration: 0.4s;
    cursor: pointer;
  }
  
  .home-page-container  .button1 {
    background-color: rgba(255, 255, 255, 1);
    color: black;
    border: 4px solid #4CAF50;
  }
  
  .home-page-container  .button2 {
    background-color: white;
    color: black;
    border: 4px solid #008CBA;
  }

  .home-page-container  .button3 {
    background-color: white;
    color: black;
    border: 4px solid #00c0c0;
  }

  .home-page-container  .button1:hover {
    background-color: #4CAF50;
    color: white;
  }

  .home-page-container  .button2:hover {
    background-color: #008CBA;
    color: white;
  }

  .home-page-container  .button3:hover {
    background-color: #00c0c0;
    color: white;
  }

  .home-page-container  h1{
    /* font-family: 'Verdana'; */
    font-family: 'Papyrus', Verdana, sans-serif;
    font-weight: bold;
    font-size: 48px;
    height: 100px;
    width: 500px;
    color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
  }

  .home-page-container  body {
    margin: 0;
    /* font: normal 75% Arial, Helvetica, sans-serif; */
  }

  .home-page-container  canvas {
    display: block;
    /* vertical-align: bottom; */
  }

  .home-page-container  .blur{
    max-width: 700px;
    margin: 50px auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    background-color: rgba(0, 0, 0, 0.15);
    backdrop-filter: blur(10px);
  }

  .home-page-container  .pc {
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    border: none;
  }
  
  .home-page-container  #tsparticles {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
  }

  .home-page-container  #light {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }


  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    /* bottom: 0; */
    background: rgba(0, 0, 0, 0);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .modal-content {
    background: #ccc;
    padding: 20px;
    border-radius: 8px;
    width: 700px;
    /* max-width: 90%; */
    max-height: 80vh; /* Set a max height */
    overflow-y: auto;
    position: relative;
    border: 10px #000000;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 30px;
    cursor: pointer;
  }
  